import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import { HeroGradient, HeroImage, Hero, Cta } from "@components/features/common"
import { FeaturesIcons } from "@components/features/icons"
import { FeaturesPanels } from "@components/features/panels"
import styled, { useTheme } from "styled-components"
import FeaturesEndOfPageCTA from "@components/cta/eop-features"
import pmGradient from "@images/features/pm-gradient.jpg"
import Span from "@components/elements/span"
import theme from "@styles/theme"
import media from "@styles/media"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import Image from "@components/image"
import Div from "@components/elements/div"
import Anchor from "@components/elements/anchor"
import Button from "@components/elements/button"

const FeaturesWrapper = styled(Flex)`
  p,
  h1,
  h2,
  h3,
  h4,
  li {
    color: "#21264f";
  }

  p a {
    color: return "#4B57C5";
  }
`

const HeroImageWrapper = styled(Div)`
  ${media.md`
    order: -1;
  `}
`

const Features = ({ data, pageContext }) => {
  const { color } = useTheme()
  const features = data.features
  const hasIcons = features.icons?.length > 0
  const hasPanels = features.panels?.length > 0
  const image = features.hero
  const imageUrl = image?.cdn
  const firstPanel = features.first_panel_title && [
    {
      title: features.first_panel_title,
      title_highlight: features.first_panel_title_highlight,
      image: features.first_panel_image,
      description: features.first_panel_description,
    },
  ]

  return (
    <Layout
      image={imageUrl}
      fullWidth={true}
      canonical={pageContext.slug}
      hideOverflow
      seo={features.seo}
      date_updated={features.date_updated}
    >
      <Container>
        <FeaturesWrapper
          margin="6rem 0 0"
          marginMd={features.cta_title ? "0 0 0" : "0 0 6rem"}
          marginSm={features.cta_title ? "0 0 0" : "0 0 4rem"}
          gap="12rem"
          gapMd="6rem"
          gapSm="6rem"
        >
          <Hero>
            <Flex maxWidth={"400px"} margin="0 auto" alignItems="flex-start">
              <Heading
                maxWidth="18ch"
                fontSize="3.4rem"
                lineHeight="125%"
                margin="0 0 2rem"
              >
                {features.title}{" "}
                <Span color={theme.color.pmGradient100}>
                  {features.title_highlight}
                </Span>
              </Heading>
              <Heading
                as="h2"
                fontSize={"1.4rem"}
                lineHeight="1.7"
                fontWeight="400"
                maxWidth="35ch"
                margin="0 0 2rem"
              >
                {features.description}
              </Heading>
              <Button.PowerMetrics noticeColor={color.indigo600} data-cta-location="header" />
            </Flex>
            <HeroImageWrapper position="relative">
              <HeroImage>
                {features.hero && (
                  <Image
                    eager
                    file={features.hero}
                    alt={`${features.title} ${features.title_highlight}`}
                  />
                )}
              </HeroImage>
              <HeroGradient src={pmGradient} />
            </HeroImageWrapper>
          </Hero>

          {firstPanel && <FeaturesPanels panels={firstPanel} />}

          {features.icons_before_panels ? (
            <>
              {hasIcons && (
                <FeaturesIcons
                  icons={features.icons}
                  header={features.icon_header}
                  highlight={features.icon_header_highlight}
                  description={features.icon_description}
                />
              )}
              {hasPanels && (
                <FeaturesPanels
                  panels={features.panels}
                  offset={firstPanel ? 1 : 0}
                />
              )}
            </>
          ) : (
            <>
              {hasPanels && (
                <FeaturesPanels
                  panels={features.panels}
                  offset={firstPanel ? 1 : 0}
                />
              )}
              {hasIcons && (
                <FeaturesIcons
                  icons={features.icons}
                  header={features.icon_header}
                  highlight={features.icon_header_highlight}
                  description={features.icon_description}
                />
              )}
            </>
          )}

          {features.icons_secondary?.length > 0 && (
            <FeaturesIcons
              icons={features.icons_secondary}
              header={features.icon_secondary_header}
              highlight={features.icon_secondary_header_highlight}
              description={null}
            />
          )}

          <FeaturesEndOfPageCTA endsPage={!features.cta_title} />

          {features.cta_title && (
            <Cta>
              <Heading
                maxWidth="18ch"
                fontSize="3.4rem"
                lineHeight="125%"
                margin="0 0 2rem"
              >
                Next:
                <br />
                <Span color={theme.color.pmGradient100}>
                  {features.cta_title}
                </Span>
              </Heading>
              <Paragraph margin="0 0 2rem">
                {features.cta_description}
              </Paragraph>
              <Anchor href={features.cta_link} arrow>
                Learn more
              </Anchor>
            </Cta>
          )}
        </FeaturesWrapper>
      </Container>
    </Layout>
  )
}

Features.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Features

export const pageQuery = graphql`
  query FeaturesPageQuery($id: String!) {
    features: featuresPage(id: { eq: $id }) {
      date_updated
      title
      title_highlight
      status
      id
      cta_button
      cta_description
      cta_title
      cta_link
      description
      hero {
        title
        cdn
        placeholder
      }
      first_panel_title
      first_panel_title_highlight
      first_panel_description
      first_panel_image {
        cdn
        title
        placeholder
      }
      icons_before_panels
      icon_header
      icon_header_highlight
      icon_description
      icons {
        title
        description
        icon {
          title
          cdn
          placeholder
        }
      }
      icon_secondary_header
      icon_secondary_header_highlight
      icons_secondary {
        title
        description
        icon {
          cdn
          title
          placeholder
        }
      }
      panels {
        title
        title_highlight
        description
        image {
          title
          cdn
          placeholder
        }
      }
      seo {
        ...seoData
      }
    }
  }
`
